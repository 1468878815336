import React from "react"
import { StaticQuery, graphql } from "gatsby"

import AppBase from "../util/app-base"

import Header from "../components/header"
import Footer from "../components/footer"

import DocumentList from "../components/download-page/document-list"
import SiteEnclosingWrapper from "../components/site-enclosing-wrapper"
import { InternalRainbowLink } from "../components/rainbow-link"

export default () => (
  <AppBase title="FLAGincluded - Downloads">
    <SiteEnclosingWrapper>
      <Header
        button={
          <InternalRainbowLink
            title="Spenden"
            href="/spenden"
            fillWidth={false}
          />
        }
      />
      <StaticQuery
        query={graphql`
          {
            pdfs: allDocuments(
              filter: { childFile: { extension: { eq: "pdf" } } }
            ) {
              nodes {
                Url
                DisplayName
                Order
                children {
                  ... on File {
                    name
                    children {
                      ... on File {
                        childImageSharp {
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                      }
                    }
                  }
                }
              }
            }
            images: allDocuments(
              filter: {
                childFile: { extension: { in: ["jpg", "jpeg", "png"] } }
              }
            ) {
              nodes {
                Url
                DisplayName
                Order
                children {
                  ... on File {
                    name
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const pdfs = data.pdfs.nodes.map(document => {
            const url = document.Url
            const name = document.DisplayName
            const order = document.Order

            const fileNode = document.children[0]
            const thumbnailNode = fileNode.children[0]
            const thumbnailImage = thumbnailNode.childImageSharp.gatsbyImageData

            return {
              documentType: "pdf",
              order,
              title: name,
              downloadLink: url,
              thumbnail: thumbnailImage,
            }
          })

          const images = data.images.nodes.map(document => {
            const url = document.Url
            const name = document.DisplayName
            const order = document.Order

            const fileNode = document.children[0]
            const thumbnailImage = fileNode.childImageSharp.gatsbyImageData

            return {
              documentType: "Bild",
              order,
              title: name,
              downloadLink: url,
              thumbnail: thumbnailImage,
            }
          })

          const documents = [...pdfs, ...images].sort((a, b) =>
            a.order > b.order ? 1 : -1
          )

          return <DocumentList documents={documents} />
        }}
      />
    </SiteEnclosingWrapper>
    <Footer />
  </AppBase>
)
