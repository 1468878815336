import React from "react"
import * as styles from "./document-list.module.scss"

import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "react-ga"

export default ({ documents = [] }) => (
  <div className={styles.documentContainer}>
    {documents.map(({ title, thumbnail, downloadLink, documentType }) => (
      <div key={title} className={styles.documentElement}>
        <GatsbyImage
          className={styles.thumbnail}
          image={thumbnail}
          alt="document preview"
        />
        <span className={styles.documentType}>{documentType}</span>
        <h3>{title}</h3>
        <div className={styles.actionButtons}>
          {/* TODO: actually make it work
          <OutboundLink to={downloadLink}>Anzeigen</OutboundLink>
          <OutboundLink
            to={downloadLink}
            download
            target="_blank"
            rel="noreferrer"
          >
            Download
          </OutboundLink>*/}
          <OutboundLink to={downloadLink} target="_blank" rel="noreferrer">
            Öffnen
          </OutboundLink>
        </div>
      </div>
    ))}
  </div>
)
